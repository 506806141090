<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between">
      <span>Configurações da Pagina de Agendamento</span>
      <v-switch label="Ativar" v-model="form.app_clinic_status" />
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          Configure aqui as informações que serão exibidas na página de
          agendamento
        </v-col>
      </v-row>
      <template v-if="form.app_clinic_status">
        <v-row class="d-flex align-baseline">
          <v-col md="6" cols="12">
            <app-text-field prepend-inner-icon="mdi-link" v-model="form.app_clinic_slug" type="url"
              v-if="!$vuetify.breakpoint.mobile" :prefix="appClinicBaseUrl" persistent-hint />
            <app-text-field prepend-inner-icon="mdi-link" v-model="form.app_clinic_slug" type="url" label="Link"
              v-if="$vuetify.breakpoint.mobile" :hint="form.app_clinic_slug
                ? appClinicBaseUrl + form.app_clinic_slug
                : ''
                " />
          </v-col>

          <v-col>
            <v-select :items="intervals" v-model="form.interval_booking" label="Intervalo de agendamento" dense />
          </v-col>

          <v-col>
            <v-select :items="sessionModalities" v-model="form.session_modality" label="Forma de atendimento"
              hide-details dense />
          </v-col>
        </v-row>
        <v-row v-if="form.app_clinic_slug" class="d-flex align-center">
          <v-col class="d-flex" cols="12" md="6">
            <v-btn class="mr-4" @click="handleCopyLink()" outlined v-if="clipboardSupported" color="primary" small>
              <app-icon left>content_copy</app-icon>
              Copiar Link
            </v-btn>
            <v-btn @click="handleShareLink()" v-if="shareLinkSupported" outlined color="primary" small>
              <app-icon left>share</app-icon>
              Compartilhar
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="handleStore()" color="primary"> Salvar </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      sessionModalities: [
        { text: "Presencial", value: "presential" },
        { text: "Online", value: "online" },
        { text: "Presencial e Online", value: "both" },
      ],

      intervals: [
        { text: '5 minutos', value: 5 },
        { text: '10 minutos', value: 10 },
        { text: '15 minutos', value: 15 }
      ],

      form: {},
      shareLinkSupported: false,

      baseForm: {
        app_clinic_slug: null,
        app_clinic_status: false,
      },
    };
  },
  created() {
    this.shareLinkSupported = "share" in navigator;
  },

  computed: {
    appClinicBaseUrl() {
      return "https://agendar-psicoplanner.web.app/";
    },
    clipboardSupported() {
      return navigator.clipboard;
    },
    company() {
      return this.$store.state.auth.company;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  async mounted() {
    await this.select();
  },

  methods: {
    async select() {
      await this.$http.index("settings/app").then((response) => {
        this.form = JSON.parse(JSON.stringify(response.app_clinic));
        this.form.session_modality = this.user.session_modality;
        this.form.interval_booking = this.user.interval_booking;
      });
    },
    async handleStore() {
      await this.$http
        .update("settings/app", this.company.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },
    processSaved() {
      this.$auth.getLoginData();
      this.$loading.finish();
      this.$snackbar({
        message: "Configurações salvas com sucesso!",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    handleCopyLink() {
      navigator.clipboard
        .writeText(`${this.appClinicBaseUrl}${this.form.app_clinic_slug} `)
        .then((response) => {
          this.$snackbar({
            message: "Link copiado com sucesso!",
            color: "success",
            timeout: 1000,
            showClose: false,
          });
        })
        .catch((error) => { });
    },
    handleShareLink() {
      navigator.share({
        title: "Compartilhar Link",
        text: "Compartilhar Link",
        url: `${this.appClinicBaseUrl}${this.form.app_clinic_slug}`,
      });
    },
  },
};
</script>

<style></style>