<template>
  <v-row>
    <v-col cols="12" md="8" offset-md="2">
      <v-card>
        <v-card-title class="d-flex justify-center" primary-title>
          Informações básicas</v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-center">
              <AvatarForm :uploadUrl="uploadUrl" :media="user.media[0]" :associate="user" @store="processSaved()" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-text-field v-model="form.name" label="Nome" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-text-field v-model="form.email" label="Email" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-whatsapp-field v-model="form.phone_wa" label="WhatsApp" />
            </v-col>

            <v-col>
              <app-text-field v-model="form.council_registration" label="Registro Conselho" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <app-text-field v-model="form.about" label="Sobre você" />
            </v-col>
          </v-row>

          <h3 class="mt-10 mb-4"><app-icon left>pin_drop</app-icon>Endereço</h3>
          <v-row>
            <v-col cols="12" md="4">
              <app-cep-field v-model="form.main_address.postal_code" label="CEP" @address="setAddress($event)" />
            </v-col>
            <v-col cols="12" md="12">
              <app-text-field v-model="form.main_address.address" label="Endereço" />
            </v-col>
            <v-col cols="12" md="6">
              <app-text-field v-model="form.main_address.number" label=" Número" />
            </v-col>
            <v-col cols="12" md="6">
              <app-text-field v-model="form.main_address.district" label="Bairro" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" md="6">
              <app-text-field v-model="form.main_address.city" label="Cidade" />
            </v-col>
            <v-col cols="4" md="6">
              <app-text-field v-model="form.main_address.state" label="Estado" />
            </v-col>
            <v-col cols="12">
              <app-text-field v-model="form.main_address.complement" label="Complemento" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="save()" class="mr-2" color="primary"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="mt-8">
        <v-card-title class="d-flex" primary-title>
          Sincronizar calendários
        </v-card-title>
        <v-card-text>
          Sincronize com Google e Outlook com o Link Abaixo:
          <br />
          <br />
          {{ syncLink }}
        </v-card-text>
      </v-card>
      <template v-if="!hasProfessionals">
        <div class="mt-8">
          <AppSettingsForm />
        </div>
      </template>
      <div class="mt-8">
        <ProfessionalWorkingHoursForm />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AvatarForm from "@/components/app/forms/AvatarForm";
import ProfessionalWorkingHoursForm from "@/components/settings/forms/ProfessionalWorkingHoursForm.vue";
import AppSettingsForm from "@/components/settings/forms/AppSettingsForm.vue";

export default {
  components: { AvatarForm, ProfessionalWorkingHoursForm, AppSettingsForm },

  data: () => ({
    uploadUrl: "user/avatar",
    hasProfessionals: false,
    form: {
      council_registration: null,
      name: null,
      email: null,
      phone_wa: null,
      media: [],
      main_address: {
        address: null,
        number: null,
        complement: null,
        district: null,
        postal_code: null,
        city: null,
        state: null,
      },
    },
  }),

  computed: {
    user() {
      return this.$store.state.auth.userData.user;
    },

    syncLink() {
      return (
        "https://meu.psicoplanner.com.br/api/calendar-sync/" + this.user.uid
      );
    },
  },

  mounted() {
    this.form = JSON.parse(JSON.stringify(this.user));

    if (!this.form.main_address) {
      this.form.main_address = {};
    }
    this.getProfessionals();
  },

  methods: {
    async save() {
      this.$loading.start();
      await this.$http
        .update("user/user", this.user.id, this.form)
        .then((response) => {
          this.processSaved();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    async getProfessionals() {
      var { professionalCount } = await this.$http.index("user/users", {
        status: "active",
      });
      this.hasProfessionals = !professionalCount == 0;
    },

    processSaved() {
      this.$auth.getLoginData();
      this.$loading.finish();
      this.$snackbar({
        message: "Configurações salvas com sucesso!",
        color: "success",
        timeout: 1000,
        showClose: false,
      });
    },
    setAddress(address) {
      this.form.main_address = address;
    },
  },
};
</script>

<style></style>
