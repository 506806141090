<template>
  <v-badge color="transparent" avatar overlap>
    <template v-slot:badge>
      <v-btn
        v-if="userMedia.length"
        @click="deleteUserAvatar()"
        text
        icon
        color="error"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>

    <v-avatar size="150px">
      <v-img
        v-if="userMedia.length"
        @click="handleAvatarClick()"
        :src="image"
      />
      <div v-else>
        <v-icon size="150px" @click="handleAvatarClick()"
          >mdi-account-circle</v-icon
        >
      </div>
      <input
        class="d-none"
        ref="fileInput"
        type="file"
        accept="image/*"
        @input="handleFileInput()"
      />
    </v-avatar>
  </v-badge>
</template>

<script>
import MediaMixin from "@/mixins/Media/MediaMixin";
export default {
  mixins: [MediaMixin],

  props: {
    uploadUrl: {},
    associate: {},
    media: {},
  },

  data: () => ({
    image: null,

    form: {
      avatar: null,
    },
  }),

  computed: {
    avatarPath() {
      return this.getImage(this.media);
    },
    userMedia() {
      return this.$store.state.auth.user.media;
    },
  },

  watch: {
    avatarPath(val) {
      this.image = val;
    },
  },

  created() {
    this.image = this.avatarPath;
  },

  methods: {
    deleteUserAvatar() {
      this.$loading.start();

      this.$http
        .destroy(this.uploadUrl, this.associate.id)
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },
    handleAvatarClick() {
      this.$refs.fileInput.click();
    },

    handleFileInput() {
      let input = this.$refs.fileInput;
      let files = input.files;

      if (files && files[0]) {
        let reader = new FileReader();

        reader.onload = (e) => {
          this.image = e.target.result;
          this.form.avatar = files[0];
          this.storeMedia();
        };

        reader.readAsDataURL(files[0]);
      }
    },

    getFormData() {
      let form = new FormData();

      form.append("avatar", this.form.avatar);

      return form;
    },

    async storeMedia() {
      this.$loading.start();

      await this.$http
        .$post(`${this.uploadUrl}/${this.associate.id}`, this.getFormData())
        .then((response) => {
          this.$emit("store");
          this.$loading.finish();
        })
        .catch((erro) => {
          this.$loading.finish();
        });
    },
  },
};
</script>

<style></style>
