<template>
  <v-card>
    <v-card-title>Horários de atendimento</v-card-title>
    <template>
      <v-card-text v-if="!hasUserWorkingHours">
        <br />
        <div class="text-center">
          <span>
            Configure seus horários seus horários de trabalho e organize melhor
            sua agenda!
          </span>
          <br />
          <span>
            Os horários disponíveis para agendamento serão exibidos na sua
            agenda.
          </span>
          <br />
          <v-btn @click="setDefaultWorkingHours()" class="mt-5" color="primary">
            Definir horários
          </v-btn>
        </div>
      </v-card-text>
    </template>

    <v-card-text v-if="hasUserWorkingHours">
      <template v-for="(week_day, dayIndex) in weekDays">
        <v-card class="mt-4" outlined>
          <v-card-text>
            <div :key="dayIndex">
              <v-row>
                <v-col class="d-flex align-center">
                  <v-switch
                    class="mt-0 mr-2"
                    hide-details
                    dense
                    @change="toggleDay(week_day)"
                    :value="hasHours(week_day)"
                    :value-comparator="(a) => a === hasHours(week_day)"
                  />
                  <h3 class="mt-1">{{ week_day.text }}</h3>
                </v-col>
              </v-row>
              <template v-if="hasHours(week_day)">
                <template
                  v-for="(working_hour, hourIndex) in getWeekWorkingHours(
                    week_day
                  )"
                >
                  <v-row
                    class="align-baseline"
                    :key="`dayIndex-${dayIndex}hourIndex-${hourIndex}`"
                  >
                    <v-col class="pt-0" md="3">
                      <app-time-picker v-model="working_hour.starts_at" />
                    </v-col>
                    Até
                    <v-col class="pt-0" md="3">
                      <app-time-picker v-model="working_hour.ends_at" />
                    </v-col>
                    <v-icon
                      @click="removeWorkingHour(working_hour)"
                      v-if="hourIndex != 0"
                      color="secondary"
                    >
                      mdi-close
                    </v-icon>
                  </v-row>
                </template>
                <v-row>
                  <v-col class="pt-0" md="7">
                    <v-btn
                      small
                      @click="addWorkingHour(week_day)"
                      color="primary"
                      text
                    >
                      Adicionar horário
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="hasUserWorkingHours || hasWorkingHours"
        @click="storeWorkDays()"
        class="mr-2"
        color="primary"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      weekDays: [
        { value: "sunday", number: 1, text: "Domingo" },
        { value: "monday", number: 2, text: "Segunda-feira" },
        { value: "tuesday", number: 3, text: "Terça-feira" },
        { value: "wednesday", number: 4, text: "Quarta-feira" },
        { value: "thursday", number: 5, text: "Quinta-feira" },
        { value: "friday", number: 6, text: "Sexta-feira" },
        { value: "saturday", number: 7, text: "Sábado" },
      ],

      hasUserWorkingHours: false,

      workingHours: [],

      defaultWorkingHours: [
        {
          week_day: "monday",
          is_working: true,
          starts_at: "08:00:00",
          ends_at: "12:00:00",
          week_day_number: 2,
        },
        {
          week_day: "monday",
          is_working: true,
          starts_at: "13:30:00",
          ends_at: "18:00:00",
          week_day_number: 2,
        },
        {
          week_day: "tuesday",
          is_working: true,
          starts_at: "08:00:00",
          ends_at: "12:00:00",
          week_day_number: 3,
        },
        {
          week_day: "tuesday",
          is_working: true,
          starts_at: "13:30:00",
          ends_at: "18:00:00",
          week_day_number: 3,
        },
        {
          week_day: "wednesday",
          is_working: true,
          starts_at: "08:00:00",
          ends_at: "12:00:00",
          week_day_number: 4,
        },
        {
          week_day: "wednesday",
          is_working: true,
          starts_at: "13:30:00",
          ends_at: "18:00:00",
          week_day_number: 4,
        },
        {
          week_day: "thursday",
          is_working: true,
          starts_at: "08:00:00",
          ends_at: "12:00:00",
          week_day_number: 5,
        },
        {
          week_day: "thursday",
          is_working: true,
          starts_at: "13:30:00",
          ends_at: "18:00:00",
          week_day_number: 5,
        },
        {
          week_day: "friday",
          is_working: true,
          starts_at: "08:00:00",
          ends_at: "12:00:00",
          week_day_number: 6,
        },
        {
          week_day: "friday",
          is_working: true,
          starts_at: "13:30:00",
          ends_at: "18:00:00",
          week_day_number: 6,
        },
      ],
    };
  },

  computed: {
    hasWorkingHours() {
      return this.workingHours.length > 0;
    },
  },
  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.$loading.start();
      this.$http
        .index("/user/working-hours")
        .then((response) => {
          this.hasUserWorkingHours = response.workingHours.length > 0;
          if (this.hasUserWorkingHours) {
            this.workingHours = response.workingHours;
          }
          this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    removeWorkingHour(working_hour) {
      var working_hour_index = this.workingHours.indexOf(working_hour);
      this.$delete(this.workingHours, working_hour_index);
    },

    addWorkingHour(week_day) {
      this.workingHours.push({
        week_day: week_day.value,
        week_day_number: week_day.number,
        is_working: true,
        starts_at: "08:00:00",
        ends_at: "18:00:00",
      });
    },
    hasHours(week_day) {
      var hours = this.getWeekWorkingHours(week_day);
      return hours.length != 0;
    },

    toggleDay(week_day) {
      var hours = this.getWeekWorkingHours(week_day);

      if (!this.hasHours(week_day)) {
        this.workingHours.push(
          {
            week_day: week_day.value,
            week_day_number: week_day.number,
            is_working: true,
            starts_at: "08:00:00",
            ends_at: "12:00:00",
          },
          {
            week_day: week_day.value,
            week_day_number: week_day.number,
            is_working: true,
            starts_at: "13:30:00",
            ends_at: "18:00:00",
          }
        );
      }
      if (this.hasHours(week_day)) {
        hours.forEach((hour) => {
          this.removeWorkingHour(hour);
        });
      }
    },

    storeWorkDays() {
      this.$loading.start();
      this.$http
        .store("user/working-hours", { workingHours: this.workingHours })
        .then(async (res) => {
          this.load();
          await this.$auth.getLoginData();

          this.$snackbar({
            message: "Configurações salvas com sucesso!",
            color: "success",
            timeout: 2000,
            showClose: false,
          });
          // this.$loading.finish();
        })
        .catch((error) => {
          this.$loading.finish();
        });
    },

    getWeekWorkingHours(week_day) {
      return this.workingHours.filter((workingHour) => {
        return (
          workingHour.week_day == week_day.value &&
          workingHour.week_day_number == week_day.number
        );
      });
    },
    setDefaultWorkingHours() {
      this.hasUserWorkingHours = true;
      this.workingHours = JSON.parse(JSON.stringify(this.defaultWorkingHours));
    },
  },
};
</script>

<style></style>
